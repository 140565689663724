import React, { useEffect } from "react";
import $ from "jquery";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const Knowledge = () => {
  const lang = "en";

  useEffect(() => {
    $(".knowledge-wrapper--header").click(function () {
      if (!$(this).parent().hasClass("open")) {
        $(".knowledge-wrapper.open .knowledge-wrapper--header")
          .next()
          .slideUp();
        $(".knowledge-wrapper.open").removeClass("open");
      }
      $(this).next().slideToggle();
      $(this).parent().toggleClass("open");
    });
  });

  return (
    <Layout
      seoTitle="Knowledge base"
      lang={lang}
      translationPL="/baza-wiedzy/"
      translationDE="/de/wissensdatenbank/"
    >
      <SmallBanner title="Knowledge base" lang={lang} />

      <section className="knowledge-main">
        <div className="container">
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What is the difference between an EPAL pallet and a
              non-standardised pallet?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  EPAL 1 is the most popular pallet in Europe with dimensions of
                  800×1200 mm. The deck boards (arranged lengthwise) and the
                  three stringers arranged along the pallet are made of solid
                  wood, while the blocks can be made of solid or particle wood.
                  EPAL pallets are certified products and properly marked. Their
                  production and repair are governed by special regulations. The
                  purchase of a standardised pallet guarantees the quality,
                  reusability, and longevity of the pallet.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What do the markings on an EPAL pallet block mean?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  On the centre block, each EPAL Euro-pallet must have the
                  manufacturer’s licence number and the year and month of
                  manufacture.
                  <br />
                  Licence number – a three-digit designation.
                  <br />
                  Year of manufacture – a single-digit designation.
                  <br />
                  Month of manufacture – a two-digit designation. <br />
                  Every new EPAL Euro-pallet manufactured under the guaranteed
                  EPAL quality system must undergo ISPM15 phytosanitary
                  treatment. Placing an appropriate mark on the central block
                  confirms that phytosanitary treatment has been done.
                  <img
                    src={require("../../assets/img/faq-ask-2.png")}
                    alt="oznaczenie palet"
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What does the marking ‘HT’ on the pallet mean?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Heat treatment within the meaning of ISPM 15 (International
                  Standards for Phytosanitary Measures No. 15) consists in
                  heating the wood in such a way that a temperature of at least
                  56°C is reached in its core, i.e. in the areas that are the
                  farthest from the surface. This temperature must be maintained
                  for at least 30 minutes. If this is done, the wood is
                  considered to have been thermally sterilised, i.e. the vast
                  majority of known organisms are irreversibly inactivated.
                  Wooden packaging, e.g. a pallet, subjected to such treatment
                  may be marked with the letters HT, which stand for Heat
                  Treatment.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What is the difference between ‘one in one’ and ‘one on one’
              loading methods?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  <strong>One in one:</strong>
                  <br />
                  Pallets are arranged in nested structures, which enable one to
                  be placed onto (inserted into) another. They are interspersed
                  in such a way that every other pallet is turned upside down
                  and its blocks go between the blocks of the next one, thus
                  allowing maximum use of the available loading and storage
                  space. This method of stacking may prevent the automatic
                  picking of pallets from a pile in production lines which are
                  not standardised.
                  <br />
                  <strong>One on one:</strong>
                  <br />
                  The pallets are stacked one on top of another in column
                  stacks. Each pallet stands on the top deck of another.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What are the benefits of loading pallets one on top of another?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Pallets arranged in the 1/1 (one on one) system are dedicated
                  to customers who have automated production lines for loading
                  pallets directly onto the production belt.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What is the moisture content of a standard pallet?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  The moisture content of new Euro-pallets must not exceed 22%.
                  On the other hand, special pallets produced at the customer’s
                  request can have any moisture content levels.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What is the difference between blue stain fungi and mould?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Wood becomes mouldy because of fungal growth that results from
                  inappropriate storage of wood. The appearance of mould is
                  favoured by high humidity, limited air circulation, and
                  moderately high temperatures. The quality of mould-infected
                  wood deteriorates, so the pallet may fail to meet the required
                  standards. Therefore, it is important that the pallet
                  manufacturer has the appropriate infrastructure in place to
                  minimise the risk of mould.
                  <br />
                  <br />
                  Very often, mould stains are confused with blue stain fungi,
                  which, according to the Wood Technology Institute in Poznań,
                  is only an aesthetic change of wood colour that does not
                  impair the performance of the material. A pallet discoloured
                  by blue stain fungi has not lost any of its value.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Does the appearance of blue stain fungi disqualify a pallet?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  According to the Wood Technology Institute in Poznań, blue
                  stain fungi do not impact the parameters of wood, or by
                  extension, the finished products. Pallets with blue stain
                  fungi can be safely used to carry goods.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Is it possible to design a pallet that is tailored to my needs?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Yes, it is. Our specialists will be pleased to present a
                  design and to manufacture pallets that meet your individual
                  requirements. We can also produce a pallet according to your
                  drawings.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              How soon can you deliver the pallets?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  In case of EPAL, CP, and standard industrial pallets, orders
                  are filled in Poland within 48 hours. For deliveries abroad,
                  the lead time is 2–4 working days. For special pallets, the
                  first delivery is made within 5–12 working days, subject to
                  the availability of materials. PalettenWerk offers you the
                  opportunity to arrange a buffer storage and on-call delivery.
                  In such a case, the lead time for pallets is 48 hours in
                  Poland and 2-4 working days abroad.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Can you mark pallets with non-standard signs (e.g., our logo)?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Yes, it is. It is possible to mark pallets according to a
                  design sent by the customer. This may be a company logo or
                  another symbol to distinguish the pallet.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              How do you mark pallets?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  In order to apply the symbols, we use the techniques of hot
                  stamping and printing with the use of professional,
                  high-resolution printers. Additionally, in our modern paint
                  shops it is possible to mark pallets by painting their contour
                  or the whole surface any colour.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Do you offer pallet tracking systems?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Yes, PalettenWerk – as one of the most technologically
                  advanced pallet manufacturers – offers the option to print QR
                  codes on pallets or to equip them with RFID tags. Both
                  solutions can be adapted to your own tracking system.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What does the process of pallet quality control look like?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Our plant received ISO 9001:2015 certification after meeting
                  strict quality requirements. These requirements are observed
                  at every stage of production, which involves many processes
                  and activities.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What certificates does your company hold?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  PalettenWerk have all the necessary certificates and approvals
                  for pallet production, for example: <br />
                  <ul>
                    <li>EPAL licence</li>
                    <li>ISO 9001:2015 certification</li>
                    <li>IPPC certificate</li>
                    <li>PEFC certificate</li>
                  </ul>
                  These certificates and licences are regularly renewed on the
                  basis of audits carried out by representatives of the
                  organisations which issue them.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Which regions do you deliver pallets to?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  PalettenWerk, as one of the main manufacturers of pallets in
                  Poland and Europe, export almost 70% of production, or almost
                  500,000 pallets per month. Our customers are based in 17
                  European countries. Apart from Poland, the largest foreign
                  markets include Italy, Germany, Switzerland, France, and
                  Austria. Our other sales markets are Czechia, Ukraine,
                  Lithuania, Slovakia, Romania, Denmark, Hungary, Belgium,
                  Slovenia, Serbia, the Netherlands, Spain, and the United
                  Kingdom. We are not afraid of challenges, and we are
                  constantly adding new customers with new destinations.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What does the pallet sales process look like?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  The customer’s requirement is the main factor that initiates
                  the whole process. PalettenWerk is the only correct answer if
                  the word ‘pallet’ is part of that requirement. <br /> From the
                  very first contact, our consultants take an individual
                  approach to determine the actual needs of a given customer.
                  Just as everyone has to choose the right shoe size for
                  themselves, choosing the right pallet guarantees comfort and
                  obvious savings in business. After the customer communicates
                  their requirements, the sales consultants – in cooperation
                  with the designers and the logistics department – prepare an
                  offer. It meets the customer’s expectations and provides the
                  best price-to-quality ratio. The client actively participates
                  in the whole process, influencing the final outcome. After an
                  offer is accepted, samples are produced and delivered to the
                  customer upon request. The last stage is the beginning of a
                  business relationship.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Do you use certified components?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  When using certificates such as PEFC, PalettenWerk is obliged
                  to use the services of proven suppliers who offer only
                  certified products. Everything is monitored by numerous
                  standards which have been established and are enforced by
                  global organisations. In addition, PalettenWerk itself
                  organises audits at its suppliers to check the quality of the
                  raw material supplied.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              How can I place an order for pallets with you?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Please place your orders via email with our sales consultants
                  or at{" "}
                  <a href="mailto:palety@palettenwerk.pl">
                    palety@palettenwerk.pl
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What is the minimum order quantity?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  The minimum order quantity depends on the design of the
                  pallet, and it can be specified by contacting us at 
                  <a href="mailto:palety@palettenwerk.pl">
                    palety@palettenwerk.pl
                  </a>
                  . However, please note that the minimum quantity will be
                  expressed as a three-digit number.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              What are the working hours of your company?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  The individual departments of our company have different
                  working hours. Customer service is provided from 8:00 a.m. to
                  4:00 p.m. and loading and acceptance of goods from 6 00 a.m.
                  to 10:00 p.m., Monday through Friday.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              How do you prepare your offer? What factors determine the price of
              pallets?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  The price of a pallet depends on:
                  <ul>
                    <li>the market price of timber</li>
                    <li>the pallet design</li>
                    <li>one-time volume</li>
                    <li>the total quantity of pallets ordered in a year</li>To
                    receive an offer, please send your enquiry to one of our
                    sales consultants or to{" "}
                    <a href="mailto:palety@palettenwerk.pl">
                      palety@palettenwerk.pl
                    </a>
                    .
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Can you keep minimum stock levels for me?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  Yes, we can maintain minimum stock levels. For more details,
                  please contact our sales consultant.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Knowledge;
